<template>
  <div>
    <b-card no-body class="mb-0">
      <!-- title="Gestion des Ressources Humaines" --> 
          <b-card-text>
            <div class="m-2">
              <!-- Table Top -->
              <b-row>
                <!-- Per Page -->
                <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0 pull-left"
                  style="margin-left: 0px; padding-left: 0px"
                >
                <b-form-input
                      v-model="searchQuery"
                      class="d-inline-block mr-1"
                      placeholder="Rechercher..."
                    />
                </b-col>

                <!-- Filters -->

                <!-- <list-data-table-filters
                  :status-filter.sync="statusFilter"
                  :status-options="statusOptions"
                /> -->

                <!-- nouveau -->
                <b-col cols="12" md="6">
                  <div
                    class="d-flex align-items-center justify-content-end pull-right"
                  >
                   
                    <b-button variant="primary" :to="{ name: 'project-add-new' }">
                      <span class="text-nowrap">Créer Plan Analytique = Créer Nouveau Projet</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>

            <b-table
              ref="refDataListTable"
              class="position-relative"
              :items="fetchModuleaccountingFiles"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              empty-text="Aucun resultat trouvé"
              :sort-desc.sync="isSortDirDesc"
            >
               

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <div class="text-nowrap">
                  <!-- <feather-icon
              :id="`analyticplan-row-${data.item.id}-send-icon`"
              icon="SendIcon"
              class="cursor-pointer"
              size="16"
            />
            <b-tooltip
              title="Send analyticplan"
              class="cursor-pointer"
              :target="`analyticplan-row-${data.item.id}-send-icon`"
            /> -->

            <b-button
              variant="flat-primary"
              class="btn-icon"
              @click="
                $router.push({
                name: 'analyticplan-view',
                params: { id: data.item.id },
                })
              ">
                  <feather-icon 
                  :id="`analyticplan-row-${data.item.id}-preview-icon`"
                    icon="EyeIcon" 
                    class="text-info"/> Visionner 
            </b-button>

            <b-button
              variant="flat-success"
              class="btn-icon"
              @click="
                $router.push({
                name: 'analyticplan-edit',
                params: { id: data.item.id },
                })
              ">
                  <feather-icon 
                  :id="`analyticplan-row-${data.item.id}-preview-icon`"
                    icon="EditIcon" 
                    class="text-success"/> Modifier 
            </b-button>

                 
                </div>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted"
                    >Affiché {{ dataMeta.from }} à {{ dataMeta.to }} sur
                    {{ dataMeta.of }} Enregistements</span
                  >
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalDatas"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-card-text> 

    </b-card> 
    <!-- Table Container Card -->
    <b-card no-body class="mb-0"> </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable, 
  BBadge,
  BTooltip,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useModuleList from "./useList";
import storeModule from "./storeModule";
// import ListDataTable from "./ListDataTable.vue";
import ListDataTableFilters from "./ListDataTableFilters.vue";

// sweet alert
import Ripple from "vue-ripple-directive"; 

// analyticplan 
import { codeIcon } from "../../extensions/toastification/code";
import { BTab, BCardText, VBTooltip } from "bootstrap-vue"; 

export default {
  components: {
    ListDataTableFilters,
    // ListDataTable,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,  
    BCardText,
    BTab,
  },
  data() {
    return {
      codeIcon,
    };
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {},
  methods: {
    onActivationQuestion(analyticplan) {
      this.$swal({
        title: "Action Ultra-Importante",
        text: "Voulez-vous vraiment définir comme active ?",
        icon: "question",
        timer: 10000,
        showCancelButton: true,
        confirmButtonText: "Oui [Confirmer Activation]",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // yes
          this.onActivate(analyticplan);
        } else if (result.dismiss === "cancel") {
          // stay here
        }
      });
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = "app-analyticplan";

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });

    const onActivate = (analyticplan) => {
      store
        .dispatch(APP_STORE_MODULE_NAME + "/activateModule", analyticplan.id)
        .then(() => {
          // show confirmation analyticplan
          refetchData();
        });
    };

    const isAddNewDataSidebarActive = ref(false);

    const statusOptions = [
      { label: "En Activité", value: "active" },
      { label: "Inactif", value: "inactive" },
      { label: "Non-Spécifié", value: "pending" },
    ];

    const resolveaccountingFileVariant = (direction) => {
      if (direction === "in") return "primary";
      if (direction === "out") return "warning";
      return "primary";
    };

    const resolveaccountingFileIcon = (direction) => {
      if (direction === "in") return "ArrowDownIcon";
      if (direction === "out") return "ArrowUpIcon";
      return "UserIcon";
    };

    const {
      fetchModuleaccountingFiles,
      tableColumns,
      perPage,
      currentPage,
      totalDatas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      refetchData,

      // UI
      resolveDataTypeVariant,
      resolveDataTypeIcon,
      resolveDataStatusVariant,

      // Extra Filters
      statusFilter,
    } = useModuleList();

    return {
      // Sidebar
      isAddNewDataSidebarActive,

      fetchModuleaccountingFiles,
      tableColumns,
      perPage,
      currentPage,
      totalDatas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      refetchData,

      onActivate,

      // Filter
      avatarText,

      // UI
      resolveaccountingFileIcon,
      resolveaccountingFileVariant,
      resolveDataTypeVariant,
      resolveDataTypeIcon,
      resolveDataStatusVariant,

      statusOptions,

      // Extra Filters
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
